import type {ISettingsParam, ISettingsSetter} from '@wix/tpa-settings'
import {useSettings as useYoshiSettings} from '@wix/tpa-settings/react'
import {WidgetType, useSettingsBi} from '../../../settings-commons/hooks/use-settings-bi'

export const useSettings = componentSettings => {
  const settings = useYoshiSettings()
  const {debouncedEditorEventsWidgetSettingChanged} = useSettingsBi(WidgetType.EventDetails)

  const legacyValue = (param: ISettingsParam) => componentSettings.texts[param.key] || componentSettings[param.key]

  const get = (param: ISettingsParam) => {
    const value = settings.get(param)
    return value ?? (componentSettings.texts[param.key] || componentSettings[param.key])
  }

  const getWithFallback = (param: ISettingsParam, fallbackParam: ISettingsParam) => {
    const value = settings.get(param)
    const fallbackValue = settings.get(fallbackParam)
    return value ?? fallbackValue ?? legacyValue(param) ?? legacyValue(fallbackParam)
  }

  const set: ISettingsSetter = (param, value) => {
    debouncedEditorEventsWidgetSettingChanged(param.key, value)
    settings.set(param, value)
  }

  return {
    ...settings,
    get,
    getWithFallback,
    set,
  }
}

import {SettingsParamType, createSettingsParams} from '@wix/tpa-settings'
import type {Override} from '../../commons/types/utils'

type ISettingsParams = {
  rsvpButtonText: SettingsParamType.Text
  rsvpEventButtonText: SettingsParamType.Text
  ticketedEventButtonText: SettingsParamType.Text
  readMoreText: SettingsParamType.Text
  readLessText: SettingsParamType.Text
  timeAndLocationTitleText: SettingsParamType.Text
  guestsTitleText: SettingsParamType.Text
  aboutTitleText: SettingsParamType.Text
  ticketsTitleText: SettingsParamType.Text
  scheduleTitleText: SettingsParamType.Text
  shareTitleText: SettingsParamType.Text
}

export type PageComponentTextSettings = Override<ISettingsParams, string>

export const settingsParams = createSettingsParams<ISettingsParams>({
  rsvpButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  rsvpEventButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  ticketedEventButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  readMoreText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  readLessText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  timeAndLocationTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  guestsTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  aboutTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  ticketsTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  shareTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  scheduleTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
})
